import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { Merchant2 } from './merchant.model';

import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import { Currency } from '../organization/organizations/Currency.model';
import { lastValueFrom } from 'rxjs';
@Injectable()
export class MerchantsService {
  selectedMerchant: Merchant2;
  uploadedPercentage = 0;

  baseUrl: string = environment.baseUrl;
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    public toastService: ToastService
  ) {}

  getMerchants() {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + '/Merchants/Admin/All';
      const merchantsArray: Merchant2[] = [];
      this.httpClient.get<any>(url).subscribe(
        (merchants: any) => {
          for (const merchant of merchants) {
            const currency: Currency = {
              currencyID: merchant.currencyID,
              currencyCode: merchant.currencyCode,
              longName: merchant.currencyLongName,
              precision: merchant.currencyPrecision,
            };
            const tempMerchant: Merchant2 = new Merchant2(
              merchant.merchantID,
              merchant.name,
              merchant.colorLogoURL,
              merchant.monochromeLogoURL,
              merchant.imageURL,
              merchant.color,
              merchant.darkUI === 1,
              merchant.phoneNumber,
              merchant.description,
              currency,
              merchant.taxID,
              merchant.contactEmail
            );
            merchantsArray.push(tempMerchant);
          }
          resolve(merchantsArray);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateMerchant(
    name: string,
    description: string,
    phoneNumber: string,
    color: string,
    mainImage: string,
    logo: string,
    taxID: string,
    contactEmail: string
  ) {
    const url: string = this.baseUrl + '/Merchants/Admin/SuperAdminUpdate';
    const source$ = this.httpClient.post(url, {
      merchantID: this.selectedMerchant.merchantID,
      colorLogoURL: logo,
      imageURL: mainImage,
      color,
      darkUI: '1',
      phoneNumber,
      description,
      taxID,
      contactEmail,
    });

    return lastValueFrom(source$);
  }

  addMerchant(
    name: string,
    description: string,
    phoneNumber: string,
    color: string,
    mainImage: string,
    logo: string,
    currencyID: number,
    taxID: string,
    contactEmail: string
  ) {
    const url: string = this.baseUrl + '/Merchants/Admin/Add';
    const source$ = this.httpClient.post(url, {
      name,
      colorLogoURL: logo,
      imageURL: mainImage,
      color,
      darkUI: '1',
      phoneNumber,
      description,
      currencyID,
      taxID,
      contactEmail,
    });

    return lastValueFrom(source$);
  }
}
