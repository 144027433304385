import { Injectable } from '@angular/core';
import { Section } from './products/section.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Product } from './products/product.model';
import { Menu } from './onCampusMenus/menu.model';
import { MenuItem } from './onCampusMenus/MenuItem.model';
import { RouteService } from '../../route.service';
import { Allergen } from './allergens/allergen.model';

import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import { Category } from './categories/categories.model';
import { SpareFilter } from '../../ui/spare-components/filters/filter.model';
import { lastValueFrom, map } from 'rxjs';
import { ChartData } from './models/ChartData.model';
import { DateRange } from '../../ui/spare-components/smart-datepicker/date-range.model';
import Bundle from './bundles/models/Bundle.model';
import { UploadFilesService } from '../../upload-files.service';
import { ProductModifier } from './products/productModifier.model';
import { DashboardService } from '../dashboard/dashboard.service';

import { RoundPipe } from '../../pipes/round/round.pipe';

@Injectable()
export class InventoryService {
  sections: Section[];
  sectionMap = new Map();
  sectionNames: string[];
  selectedSection = -1;
  products: Product[];
  productMap = new Map();
  selectedProduct = -1;
  possibleAllergies: Allergen[];
  alergiesMap = new Map();
  alergiesIDToNameMap = new Map();
  possibleCategories: Category[];
  categoriesNameToIDMap = new Map();
  categoriesIDToIsFood = new Map();
  sectionNameToIDMap = new Map();
  sectionIDToNameMap = new Map();
  hasProducts = false;
  selectedOrganizationID: any;
  selectedStockTraked = true;
  selectedMenuID: number;
  selectedBranchID: number;
  selectedBranchName: string;
  redirectFromStock = false;
  numberofItems: number;
  selectedAllergen: string;
  selectedAllergenObj: Allergen;
  branchBeingRestocked: number;
  uploadedPercentage = 0;
  baseUrl: string = environment.baseUrl;
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService,
    private uploadService: UploadFilesService,
    private dashboardService: DashboardService,
    private roundPipe: RoundPipe
  ) {
    if (localStorage.getItem('spareView') === 'organization') {
      this.selectedOrganizationID = localStorage.getItem('spareSelectedOrganization');
      this.possibleAllergies = null;
      this.possibleAllergies = [];
      this.alergiesMap = new Map();
      this.alergiesIDToNameMap = new Map();
    } else {
      this.getAllergies();
    }
  }

  /**
   * Checks whether the section name is already in use
   */
  nameUsed(name: string) {
    return this.sectionNames.includes(name);
  }

  /**
   * Get the section name from the section ID
   */
  getSectionName(sectionID: number) {
    return new Promise((resolve) => resolve(this.sectionIDToNameMap.get(sectionID)));
  }

  getCurrentView() {
    return localStorage.getItem('spareView');
  }
  /**
   * Calls on the API to get the menu sections created by the user
   * The Map translating IDs to name and back are generated as well
   */
  getSections(): Promise<Section[]> {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/MenuSections/Admin/All';
      this.sections = [];
      this.sectionMap = new Map();
      this.sectionIDToNameMap = new Map();
      this.sectionNameToIDMap = new Map();
      this.sectionNames = [];

      this.httpClient.get<any>(url).subscribe(
        (sectionsData: any) => {
          for (const section of sectionsData) {
            const tempSection: Section = new Section(
              section.sectionID,
              section.sectionName,
              section.merchantID,
              section.iconURL,
              section.sectionOrder
            );
            this.sections.push(tempSection);
            this.sectionMap.set(section.sectionID, tempSection);
            this.sectionNames.push(section.sectionName);
            this.sectionNameToIDMap.set(section.sectionName, section.sectionID);
            this.sectionIDToNameMap.set(section.sectionID, section.sectionName);
          }
          resolve(this.sections);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  /**
   * The function returns the section Names While also
   * populating the maps translating between section name and section ID
   */
  getSectionsNames() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/MenuSections/Admin/All';
      this.sections = [];
      this.sectionMap = new Map();
      this.sectionNames = [];
      this.httpClient.get<any>(url).subscribe(
        (sectionsData: any) => {
          for (const section of sectionsData) {
            const tempSection: Section = new Section(
              section.sectionID,
              section.sectionName,
              section.merchantID,
              section.iconURL,
              section.sectionOrder
            );
            this.sections.push(tempSection);
            this.sectionMap.set(section.sectionID, tempSection);
            this.sectionNames.push(section.sectionName);
            this.sectionNameToIDMap.set(section.sectionName, section.sectionID);
            this.sectionIDToNameMap.set(section.sectionID, section.sectionName);
          }
          resolve(this.sectionNames);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  /**
   * Returns a list of the possible allergies created by the super admin
   * It also populates the map that translates between the allergen ID and the allergen Name
   */
  getAllergies(): Promise<Allergen[]> {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Allergens/Admin/All';
      this.possibleAllergies = null;
      this.possibleAllergies = [];
      this.alergiesMap = new Map();
      this.alergiesIDToNameMap = new Map();
      this.httpClient.get<any>(url).subscribe(
        (alergiesData: any) => {
          for (const allergen of alergiesData) {
            const tempAllergen: Allergen = new Allergen(
              allergen.allergenID,
              allergen.allergenName,
              allergen.allergenName_ar,
              allergen.allergenIcon
            );
            this.possibleAllergies.push(tempAllergen);
            this.alergiesMap.set(allergen.allergenName, allergen.allergenID);
            this.alergiesIDToNameMap.set(allergen.allergenID, allergen.allergenName);
          }
          resolve(this.possibleAllergies);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  /**
   * Returns a list of the Categories names
   * It also populates the maps that translate between the category ID and the category name
   */
  getCategories() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/ProductCategories/Admin/All';
      this.possibleCategories = [];
      this.categoriesNameToIDMap = new Map();
      this.httpClient.get<any>(url).subscribe({
        next: (categoriesData: any) => {
          for (const category of categoriesData) {
            const newCat: Category = {
              categoryID: category.categoryID,
              categoryName: category.categoryName,
              isFoodBased: category.isFoodBased,
              associatedWithVoucher: category.associatedWithVoucher,
              redeemableByFamily: category.redeemableByFamily,
              autoDelivery: category.autoDelivery,
              categoryIconURL: category.categoryIconURL,
              primaryColorLight: category.primaryColorLight,
              primaryColorDark: category.primaryColorDark,
              secondaryColorLight: category.secondaryColorLight,
              secondaryColorDark: category.secondaryColorDark,
              view: category.view,
              orderInView: category.orderInView,
            };
            this.possibleCategories.push(newCat);
            this.categoriesNameToIDMap.set(category.categoryName, category.categoryID);
            this.categoriesIDToIsFood.set(category.categoryID, category.isFoodBased);
          }
          resolve(this.possibleCategories);
        },
        error: (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        },
      });
    });
  }

  /**
   * Return an array of products and also formulate a map that links the product ID to a product object
   */
  getProducts(query?: { noVouchers?: boolean, noModifiers?: boolean }): Promise<Product[]> {
    return new Promise((resolve, reject) => {

      let url = `${this.baseUrl}/Products/Admin/All`;
      if (query?.noVouchers) url += '?noVouchers=true';
      if (query?.noModifiers) url += '?noModifiers=true';

      this.httpClient.get<any>(url).subscribe(
        (productsData: any) => {
          this.products = [];
          this.productMap = new Map();
          for (const product of productsData) {
            const finalPrice = this.roundPipe.transform(product.price * (product.inclusiveVAT ? product.VAT : 1), product.currencyPrecision);
            const finalPriceString = `${finalPrice} ${product.currencyCode} ${product.inclusiveVAT ? ' (Price Includes VAT)' : ''}`;
            const tempProduct: Product = new Product({
              productID: product.productID,
              externalSKU: product.externalSKU,
              merchantID: product.merchantID,
              categoryID: product.categoryID,
              name: product.name,
              imageURL: product.imageURL,
              description: product.description,
              price: product.price,
              status: product.status,
              validityInDay: product.validityInDays,
              vat: product.VAT,
              otherTax: product.otherTax,
              inclusiveVAT: product.inclusiveVAT,
              allergenIDs: product.allergenIDs,
              sectionID: product.sectionID,
              servingSize: product.servingSize,
              currencyCode: product.currencyCode,
              currencyPrecision: product.currencyPrecision,
              orderInSection: product.orderInSection,
              voucherID: product.voucherID,
              voucherQuantity: product.voucherQuantity,
              finalPrice: finalPrice,
              finalPriceString: finalPriceString,
            });
            this.products.push(tempProduct);
            this.productMap.set(product.productID, tempProduct);
          }
          resolve(this.products);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  /**
   * Returns a list of product modifiers given productID
   */

  async getProductModifiers(productID) {
    const url = `${this.baseUrl}/Products/Admin/modifiers/${productID}`;
    return await lastValueFrom(this.httpClient.get<any>(url));
  }

  async getProductModifiersInMenu(productID, menuID) {
    const url = `${this.baseUrl}/Products/Admin/modifiers/${menuID}/${productID}`;
    return await lastValueFrom(this.httpClient.get<any>(url));
  }



  /**
   * Calls on the API to add a new section
   */
  addSection(sectionName: string, iconURL: string) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/MenuSections/Admin/Add';
      this.httpClient
        .post(url, {
          sectionName: sectionName,
          iconURL: iconURL,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Section added successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /**
   * Calls on the API to add a new product
   */
  addProduct(
    externalSKU: string,
    categoryID: number,
    name: string,
    imageURL: string,
    description: string,
    price: number,
    validityInDays: number,
    VAT: number,
    otherTax: number,
    inclusiveVAT: boolean,
    allergenIDs: string,
    servingSize: string,
    voucherID?: number,
    voucherQuantity?: number,
    productModifiers?: ProductModifier[]
  ) {
    const url: string = this.baseUrl + '/Products/Admin/Add';
    const source$ = this.httpClient.post(url, {
      externalSKU,
      categoryID,
      name,
      imageURL,
      description,
      price,
      validityInDays,
      VAT,
      otherTax,
      inclusiveVAT,
      allergenIDs,
      sectionID: this.selectedSection,
      servingSize,
      voucherID,
      voucherQuantity,
      productModifiers
    });

    return lastValueFrom(source$);
  }

  /**
   * Calls on the API to update a section
   */
  updateSection(sectionName: string, iconURL: string, sectionOrder: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/MenuSections/Admin/Update';
      this.httpClient
        .post(url, {
          sectionName: sectionName,
          sectionID: this.selectedSection,
          iconURL: iconURL,
          sectionOrder,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Section updated successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /**
   * Calls on the API to update a product
   */
  updateProduct(
    name: string,
    externalSKU: string,
    categoryID: number,
    imageURL: string,
    description: string,
    price: number,
    validityInDays: number,
    VAT: number,
    otherTax: number,
    inclusiveVAT: boolean,
    allergenIDs: string,
    servingSize: string,
    sectionID: number,
    orderInSection: number,
    voucherID?: number,
    voucherQuantity?: number,
    productModifiers?: ProductModifier[]
  ) {
    const url: string = this.baseUrl + '/Products/Admin/Update';
    const source$ = this.httpClient.post(url, {
      externalSKU,
      categoryID,
      imageURL,
      description,
      price,
      validityInDays,
      VAT,
      otherTax,
      inclusiveVAT,
      allergenIDs,
      sectionID,
      productID: this.selectedProduct,
      servingSize,
      orderInSection,
      name,
      voucherID,
      voucherQuantity,
      productModifiers
    });

    return lastValueFrom(source$);
  }

  /**
   * Calls on the API to delete a section
   * This function is not called unless the section has no products in it
   */
  deleteSection() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/MenuSections/Admin/Delete';
      this.httpClient
        .post(url, {
          sectionID: this.selectedSection,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Section deleted successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  deleteProduct(productID: number) {
    const url = `${this.baseUrl}/Products/Admin/Delete`;

    const source$ = this.httpClient.delete<any>(url, { body: { productID } });

    return lastValueFrom(source$);
  }
  /**
   * Update the status of one of the products
   */
  toogleProductStatus(status: boolean) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Products/Admin/ToggleStatus';
      this.httpClient
        .post(url, {
          status: status,
          productID: this.selectedProduct,
        })
        .subscribe(
          () => {
            resolve('success');
            if (status) {
              this.toastService.show('success', 'Success', 'Product activated successfully');
            } else {
              this.toastService.show('success', 'Success', 'Product deactivated successfully');
            }
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /**
   * Takes in a comma delimited string of allergens ID and translate it to an array of allergens
   */
  getSelectedAllergens(allergens: string) {
    return new Promise((resolve, reject) => {
      if (allergens === 'None') {
        resolve([]);
      } else {
        this.getAllergies().then(
          () => {
            const res: string[] = [];
            const allergensArray: string[] = allergens.split(',');
            for (const allergen of allergensArray) {
              if (
                allergen !== null &&
                allergen !== 'undefined' &&
                this.alergiesIDToNameMap.get(+allergen) !== null
              ) {
                res.push(this.alergiesIDToNameMap.get(+allergen));
              }
            }
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
      }
    });
  }

  /**
   * First The function gets all of the possible branches
   * It then Calls on the available menus and link each of the menus to its branch
   * The branches with no menus will have a menuID of null
   */
  getMenus(): Promise<Menu[]> {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Branches/Admin/All';
      const branches: string[] = [];
      const branchesIdMap = new Map();
      const menus: Menu[] = [];
      const branchesWithMenus: string[] = [];
      this.httpClient.get<any>(url).subscribe(
        (branchesData: any) => {
          for (const branch of branchesData) {
            branches.push(branch.branchName);
            branchesIdMap.set(branch.branchName, branch.branchID);
          }
          const url: string = this.baseUrl + '/Menu/Admin/All';
          this.httpClient.get<any>(url).subscribe(
            (menusData: any) => {
              for (const menu of menusData) {
                branchesWithMenus.push(menu.branchName);
                const tempMenu: Menu = new Menu(
                  menu.menuID,
                  menu.branchID,
                  menu.branchName,
                  menu.stockTracked,
                  menu.showInApp,
                  menu.numberOfProducts,
                  menu.numberOfSections
                );
                menus.push(tempMenu);
              }
              for (const branch of branches) {
                if (!branchesWithMenus.includes(branch)) {
                  const tempMenu: Menu = new Menu(
                    null,
                    branchesIdMap.get(branch),
                    branch,
                    null,
                    null,
                    null,
                    null
                  );
                  menus.push(tempMenu);
                }
              }
              resolve(menus);
            },
            (error: any) => {
              console.error(error);
              reject(error);
            }
          );
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }
  /**
   * First The function gets all offline of the possible branches
   * It then Calls on the available menus and link each of the menus to its branch
   * The branches with no menus will have a menuID of null
   */

  async getMenusByType(type): Promise<Menu[]> {
    try {
      const supportedTypes = ['Online', 'Offline'];
      if (!supportedTypes.includes(type)) {
        throw new Error('Invalid type');
      }

      const [branches, menus] = await Promise.all([this.loadBranches(type), this.loadMenus(type)]);

      for (const branch of branches) {
        //if we don't find branchID in menus, then we need to add an entry for it
        if (!menus.find((menu) => menu.branchID === branch.branchID)) {
          const tempMenu: Menu = new Menu(
            null,
            branch.branchID,
            branch.branchName,
            null,
            null,
            null,
            null
          );
          menus.push(tempMenu);
        }
      }

      return menus;
    } catch (err) {
      this.routeService.checkErr(err).then((err: any) => {
        this.toastService.show('danger', 'Error', err.err);
        throw err;
      });
    }
  }

  async loadMenus(type): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + `/Menu/Admin/All/${type}`;
      const menus: Menu[] = [];
      //load menus using Angular httpClient
      this.httpClient.get<any>(url).subscribe(
        (menusData: any) => {
          for (const menu of menusData) {
            const tempMenu: Menu = new Menu(
              menu.menuID,
              menu.branchID,
              menu.branchName,
              menu.stockTracked,
              menu.showInApp,
              menu.numberOfProducts,
              menu.numberOfSections
            );
            menus.push(tempMenu);
          }
          resolve(menus);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  async loadBranches(type): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + `/Branches/Admin/Menus/${type}`;
      //load branches using Angular httpClient
      this.httpClient.get<any>(url).subscribe(
        (branchesData: []) => {
          resolve(branchesData);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  removeMenuItemsFilters(productID: number, branchID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: { branchID: branchID },
      };
      this.httpClient
        .delete(`${this.baseUrl}/products-filters/Admin/${productID}`, options)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.routeService.checkErr(err).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }
  /**
   * Takes in a menuID and return an array if the menu Items inside that menu
   */
  getMenuItems(menuID: number): Promise<MenuItem[]> {
    return new Promise((resolve, reject) => {
      if (!menuID) return resolve([]);
      const itemArray: MenuItem[] = [];
      const url: string = this.baseUrl + '/MenuItem/Admin/All';
      this.httpClient
        .get<any>(url, {
          params: new HttpParams().set('menuID', '' + menuID),
        })
        .subscribe(
          (menuItems: any) => {
            for (const menuItem of menuItems) {
              const tempMenuItem: MenuItem = new MenuItem(
                menuItem.itemEntryID,
                menuItem.productID,
                menuItem.lastIncreased,
                menuItem.amountLeft,
                menuItem.externalSKU,
                menuItem.name,
                menuItem.imageURL,
                menuItem.description,
                menuItem.priceInMenu,
                menuItem.originalPrice,
                menuItem.validityInDays,
                menuItem.VAT,
                menuItem.otherTax,
                menuItem.inclusiveVAT,
                menuItem.allergenIDs,
                menuItem.sectionID,
                menuItem.servingSize,
                menuItem.sectionName,
                menuItem.iconURL,
                menuItem.status === 1,
                menuItem.currencyCode,
                menuItem.currencyPrecision,
                menuItem.allowNotes,
                menuItem.deliveryPreference,
                menuItem.notePlaceholder,
                menuItem.preparationTime,
                menuItem.deliveryDate,
                menuItem.availableDays,
                menuItem.allowsCashierSetPrice,
                menuItem.allowsCashierNote,
                menuItem.categoryAssociatedWithVoucher,
                menuItem.categoryID,
                menuItem.categoryName,
                menuItem.categoryViewMode,
                menuItem.deliveryTime,
                menuItem.orderingMode
              );
              itemArray.push(tempMenuItem);
            }
            resolve(itemArray);
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /**
   * Takes in an array of the IDs of the item entries that need to be removed and remove them from the menu
   */
  removeMenuItems(itemEntryIDs: number[]) {
    return new Promise((resolve, reject) => {
      if (itemEntryIDs === null || itemEntryIDs.length < 1) {
        resolve('success');
        return;
      } else {
        const url: string = this.baseUrl + '/MenuItem/Admin/DeleteItems';
        this.httpClient
          .post(url, {
            itemEntryIDs: itemEntryIDs,
          })
          .subscribe(
            () => {
              resolve('success');
            },
            (error) => {
              this.routeService.checkErr(error).then((err: any) => {
                reject(err);
              });
            }
          );
      }
    });
  }

  /**
   * Takes in a menuID and an array of products IDs to be added to the menu
   * Then it adds all of these product to that menu
   */
  addMenuItems(productIDs: number[], menuID: number) {
    return new Promise((resolve, reject) => {
      if (productIDs === null || productIDs.length < 1) {
        resolve('success');
        return;
      } else {
        const url: string = this.baseUrl + '/MenuItem/Admin/Add';
        this.httpClient
          .post(url, {
            products: productIDs,
            menuID: menuID,
          })
          .subscribe(
            () => {
              resolve('success');
            },
            (error) => {
              this.routeService.checkErr(error).then((err: any) => {
                reject(err);
              });
            }
          );
      }
    });
  }

  /**
   * It takes in a branch ID and create a menu and link it that branch
   */
  addMenu(branchID: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Menu/Admin/Add';
      this.httpClient
        .post(url, {
          branchID: branchID,
          stockTracked: '0',
          showInApp: '0',
        })
        .subscribe(
          () => {
            resolve('success');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /**
   * Gets in the array of items that needs to be added to
   * the menu and another array for the items that needs to be removed from the menu
   * It Calls on the addMenuItems and removeMenuItems functions to add and remove items from the menu
   */
  async updateMenuEntries(toBeAdded: number[], toBeRemoved: number[], menuID: number) {
    this.numberofItems = this.numberofItems + toBeAdded.length - toBeRemoved.length;
    try {
      await this.addMenuItems(toBeAdded, menuID);
      await this.removeMenuItems(toBeRemoved);

      this.toastService.show('success', 'Success', 'Menu updated successfully');
      return;
    } catch (err) {
      this.toastService.show('danger', 'Error', err.err);
      throw err;
    }
  }

  updateMenuItem(menuItem: any): Promise<any> {
    const source$ = this.httpClient.put(`${this.baseUrl}/inventory/menu/item/admin`, menuItem);
    return lastValueFrom(source$);
  }

  getAllBundles(): Promise<Bundle[]> {
    return new Promise((resolve, reject) => {
      let bundleArray: Bundle[] = [];
      const url: string = this.baseUrl + '/inventory/bundle/admin';
      this.httpClient.get<any>(url).subscribe(
        (bundles: any) => {
          bundleArray = bundles.map((bundle) => new Bundle(bundle));
          resolve(bundleArray);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getMenuBundles(branchID: number): Promise<Bundle[]> {
    return new Promise((resolve, reject) => {
      let bundleArray: Bundle[] = [];
      const url: string = this.baseUrl + '/inventory/bundle/admin/ByBranch/' + branchID;
      this.httpClient.get<any>(url).subscribe(
        (bundles: any) => {
          bundleArray = bundles.map((bundle) => new Bundle(bundle));
          resolve(bundleArray);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  addMenuBundle(branchID: number, bundleIDs: number[], filters?: SpareFilter[]) {
    const url = `${this.baseUrl}/inventory/bundle/admin/branch/${branchID}`;
    const source$ = this.httpClient.post(url, { bundleIDs, filters });

    return lastValueFrom(source$);
  }

  removeMenuBundle(branchID: number, bundleID: number) {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/inventory/bundle/admin/${bundleID}/branch/${branchID}`;
      this.httpClient.delete(url).subscribe(
        () => {
          resolve('success');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  /**
   * Take the selected MenuID and update the stock tracked status of that menu
   */
  toogleStockTracked(stockTracked: boolean) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Menu/Admin/Update';
      this.httpClient
        .post(url, {
          stockTracked: stockTracked,
          menuID: this.selectedMenuID,
        })
        .subscribe(
          () => {
            resolve('success');
            if (stockTracked) {
              this.toastService.show('success', 'Success', 'Stock is now tracked');
            } else {
              this.toastService.show('success', 'Success', 'Stock Tracking is now disabled');
            }
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  addCategory(
    categoryName: string,
    isFoodBased: boolean,
    associatedWithVoucher: boolean,
    redeemableByFamily: boolean,
    autoDelivery: boolean,
    categoryIconURL: string,
    primaryColorLight: string,
    primaryColorDark: string,
    secondaryColorLight: string,
    secondaryColorDark: string,
    view: string,
    orderInView: number
  ) {
    const url: string = this.baseUrl + '/ProductCategories/Admin/Add';

    const source$ = this.httpClient.post(url, {
      categoryName,
      isFoodBased,
      associatedWithVoucher,
      redeemableByFamily,
      autoDelivery,
      categoryIconURL,
      primaryColorLight,
      primaryColorDark,
      secondaryColorLight,
      secondaryColorDark,
      view,
      orderInView
    });

    return lastValueFrom(source$);
  }

  updateCategory(
    categoryID: number,
    categoryName: string,
    isFoodBased: boolean,
    associatedWithVoucher: boolean,
    redeemableByFamily: boolean,
    autoDelivery: boolean,
    categoryIconURL: string,
    primaryColorLight: string,
    primaryColorDark: string,
    secondaryColorLight: string,
    secondaryColorDark: string,
    view: string,
    orderInView: number
  ) {
    const url: string = this.baseUrl + '/ProductCategories/Admin/Update';

    const source$ = this.httpClient.post(url, {
      categoryID,
      categoryName,
      isFoodBased,
      associatedWithVoucher,
      redeemableByFamily,
      autoDelivery,
      categoryIconURL,
      primaryColorLight,
      primaryColorDark,
      secondaryColorLight,
      secondaryColorDark,
      view,
      orderInView
    });

    return lastValueFrom(source$);
  }

  /**
   * Calls on the API to add an allergen
   */
  addAllergen(allergenName: string, allergenName_ar: string, allergenIcon: string) {
    const url: string = this.baseUrl + '/Allergens/Admin/Add';
    const source$ = this.httpClient.post(url, {
      allergenName,
      allergenName_ar,
      allergenIcon,
    });

    return lastValueFrom(source$);
  }

  /**
   * Calls on the API to update an allergen
   */
  updateAllergen(allergenName: string, allergenName_ar: string, allergenIcon: string) {
    const url: string = this.baseUrl + '/Allergens/Admin/Update';
    const source$ = this.httpClient.post(url, {
      allergenName,
      allergenID: this.alergiesMap.get(this.selectedAllergenObj.allergenName),
      allergenName_ar,
      allergenIcon,
    });

    return lastValueFrom(source$);
  }

  /**
   * Calls on the API to get the sections Icons
   */
  getIcons() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/SectionIcons/Admin/All';
      this.httpClient.get<any>(url).subscribe(
        (iconsData: any) => {
          resolve(iconsData);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateIcon(iconURL, iconID) {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/SectionIcons/Admin/Update`;
      const body = { iconURL, iconID };
      this.httpClient.post(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Icon updated successfully');
        },
        (err) => {
          this.routeService.checkErr(err).then((parsedError: any) => {
            reject(parsedError);
            this.toastService.show('danger', 'Error', parsedError.err);
          });
        }
      );
    });
  }

  /**
   * Calls on the API to add an icon
   */
  async addIcon(icon: File) {
    const uploadSource$ = this.uploadService.uploadFile(icon, 'sectionIcons', 'section', true);
    const { url: iconURL } = await lastValueFrom(uploadSource$);

    const url: string = this.baseUrl + '/SectionIcons/Admin/Add';
    const source$ = this.httpClient.post(url, {
      iconURL,
    });

    return lastValueFrom(source$);
  }

  branchfilterStudentsCount(
    ignoreEmptyFilters: boolean,
    filters: SpareFilter[],
    branchID: number
  ): Promise<{ count: number }> {
    const payload = {
      ignoreEmptyFilters: ignoreEmptyFilters,
      branchID: branchID,
      filters,
    };
    const source$ = this.httpClient.post<{ count: number }>(
      `${this.baseUrl}/Child/Admin/filtered`,
      payload
    );
    return lastValueFrom(source$);
  }
  branchDistinctStudentsValue(field: string, branchID: number): Promise<any[]> {
    const source$ = this.httpClient
      .get<{ value: any }[]>(`${this.baseUrl}/Child/Admin/distinct`, {
        params: new HttpParams().set('field', field).set('branchID', branchID),
      })
      .pipe(map((res) => res.map((r) => r.value)));
    return lastValueFrom(source$);
  }
  addingProductFilters(filters: SpareFilter[], productIDs: number[]): Promise<any> {
    const payload = {
      branchID: this.selectedBranchID,
      productIDs,
      filters,
    };
    const source$ = this.httpClient.post(`${this.baseUrl}/products-filters/Admin`, payload);
    return lastValueFrom(source$);
  }
  editProductFilters(filters: SpareFilter[], productId: number): Promise<any> {
    const payload = {
      branchID: this.selectedBranchID,
      productID: productId,
      filters,
    };
    const source$ = this.httpClient.put(`${this.baseUrl}/products-filters/Admin/`, payload);
    return lastValueFrom(source$);
  }

  getFiltersByProductID(productID: number, branchID: number): Promise<SpareFilter[]> {
    return new Promise<SpareFilter[]>((resolve, reject) => {
      this.httpClient
        .get(
          `${this.baseUrl}/products-filters/Admin/${productID.toString()}/${branchID.toString()}`
        )
        .subscribe(
          (res: any) => {
            const spareFilters: SpareFilter[] = res
              .map((filter) => {
                return {
                  filterKey: filter.filterKey,
                  modifier: filter.modifier === 1,
                  value: filter.value?.split(','),
                };
              })
              .filter((filter) => filter.filterKey);
            resolve(spareFilters);
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  filterStudentsCount(
    ignoreEmptyFilters: boolean,
    filters: SpareFilter[],
    branchID?: number
  ): Promise<{ count: number }> {
    if (this.getCurrentView() === 'merchant') {
      return this.branchfilterStudentsCount(ignoreEmptyFilters, filters, branchID);
    } else if (this.getCurrentView() === 'organization') {
      return this.orgFilterStudentsCount(ignoreEmptyFilters, filters);
    }
  }

  distinctStudentsValue(field: string, branchID?: number): Promise<any[]> {
    if (branchID) {
      return this.branchDistinctStudentsValue(field, branchID);
    } else {
      return this.orgDistinctStudentsValue(field);
    }
  }
  orgFilterStudentsCount(
    ignoreEmptyFilters: boolean,
    filters: SpareFilter[]
  ): Promise<{ count: number }> {
    const payload = {
      ignoreEmptyFilters: ignoreEmptyFilters,
      organizationID: this.selectedOrganizationID,
      filters,
    };
    const source$ = this.httpClient.post<{ count: number }>(
      `${this.baseUrl}/Child/Admin-org/filterd`,
      payload
    );
    return lastValueFrom(source$);
  }

  orgDistinctStudentsValue(field: string): Promise<any[]> {
    const source$ = this.httpClient
      .get<{ value: any }[]>(`${this.baseUrl}/Child/Admin-org/distinct`, {
        params: new HttpParams()
          .set('field', field)
          .set('organizationID', this.selectedOrganizationID),
      })
      .pipe(map((res) => res.map((r) => r.value)));
    return lastValueFrom(source$);
  }
  getBranchSalesChartData(branchID: number, dateRange: DateRange): Promise<ChartData> {
    return new Promise<ChartData>((resolve, reject) => {
      let url = this.baseUrl + `/Transactions/Admin/soldProductsMetrics/${branchID}`;
      const dateFrom = dateRange.startDate.unix() * 1000;
      const dateTo = dateRange.endDate.unix() * 1000;
      if (dateFrom && dateTo) url += `?from=${dateFrom}&to=${dateTo}`;
      const res = this.httpClient.get(url).subscribe(
        {
          next: (res: any) => {
            console.log('branch sales>>>>', res);
            const data: ChartData = {
              labels: res.map((item) => item.name),
              valuesSold: res.map((item) => item.numberSold),
              valuesReturned: res.map((item) => item.numberReturned),
              colorSold: new Array(res.length).fill('#00a0c3'),
              colorReturned: new Array(res.length).fill('#f44336'),
            };
            resolve(data);
          }
          ,
          error: (err) => {
            this.routeService.checkErr(err).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        }
      );
    });
  }

  /**
   * Updates menu on whether it can appear in the client app
   * @param showInApp
   * @param menuID
   */
  updateShowInApp(showInApp: boolean, menuID: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Menu/Admin/Update/showInApp';
      this.httpClient
        .post(url, {
          showInApp,
          menuID,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show(
              'success',
              'Success',
              'Show Menu In App was updated successfully'
            );
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  getMenu(menuID: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Menu/Admin/Menu?menuID=' + menuID;
      this.httpClient.get(url).subscribe(
        (res) => {
          resolve(res[0]);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
          });
        }
      );
    });
  }

  getSoldProductsBreakdown(branchID, dateRange: DateRange, returns: boolean): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let url: string = this.baseUrl + '/transactions/Admin/SoldProductsBreakdown/?';
      const dateFrom = dateRange.startDate.unix() * 1000;
      const dateTo = dateRange.endDate.unix() * 1000;
      if (dateFrom && dateTo) url += `from=${dateFrom}&to=${dateTo}`;
      if (branchID) url += `&branchID=${branchID}`;
      if (returns) url += `&returns=${returns}`;
      this.httpClient.get(url).subscribe(
        (res: any[]) => {
          res.pop();
          resolve(res);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
          });
        }
      );
    });
  }

  getFinancialReport(body: { breakdown: string; branchIDs?; fromDate?; toDate?; organizationIDs?}) {
    const url = this.baseUrl + '/merchant-gateway/report/financial';
    const res = lastValueFrom(this.httpClient.get<any>(url, { params: body }));
    return res;
  }

  isValidPricePrecision(price: number, currencyPrecision?: number): boolean {
    currencyPrecision ||= this.dashboardService.currentMerchant?.currencyPrecision || 2;
    return price.toString().match(new RegExp(`^[0-9]+(\\.[0-9]{1,${currencyPrecision}})?$`)) !== null;
  }
}
