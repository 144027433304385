import { Branch } from './branch.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../../route.service';
import { ToastService } from '../../../toast.service';
import { environment } from '../../../../environments/environment';
import { SalesBranch } from '../../inventory/models/salesBranch';
import { lastValueFrom, map } from 'rxjs';

@Injectable()
export class BranchesService {
  branchIdMap = new Map();

  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    public toastService: ToastService
  ) {}

  baseUrl: string = environment.baseUrl;
  // Get the branches data from the api
  getData() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Branches/Admin/All';
      const branchesArray: Branch[] = [];
      this.httpClient.get<any>(url).subscribe(
        (branches: any) => {
          for (const branch of branches) {
            const tempBranch: Branch = new Branch(
              branch.branchName,
              branch.area,
              branch.address,
              branch.canLoadUp === 1,
              branch.canRedeem === 1,
              branch.organizationName,
              branch.isOnlineBranch,
              branch.autoDeliversProducts,
              branch.tenders,
              branch.externalBranchUUID,
              branch.branchID
            );
            branchesArray.push(tempBranch);
          }
          resolve(branchesArray);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  loadBranches(): Promise<Branch[]> {
    const url = this.baseUrl + '/Branches/Admin/All';
    const source$ = this.httpClient
      .get<any[]>(url)
      .pipe(
        map((branches) =>
          branches.map(
            (branch) =>
              new Branch(
                branch.branchName,
                branch.area,
                branch.address,
                branch.canLoadUp,
                branch.canRedeem,
                branch.organizationName,
                branch.isOnlineBranch,
                branch.autoDeliversProducts,
                branch.tenders,
                branch.externalBranchUUID,
                branch.branchID
              )
          )
        )
      );

    return lastValueFrom(source$);
  }

  // Update a branch
  updateBranch(
    address: string,
    externalBranchUUID: string,
    canLoadUp: boolean,
    canRedeem: boolean,
    autoDelivers: boolean,
    tenderIDs: number[],
    branchID: number
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Branches/Admin/Update';
      this.httpClient
        .post(url, {
          branchID,
          address,
          externalBranchUUID,
          canLoadUp,
          canRedeem,
          autoDelivers,
          tenderIDs,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Branch updated');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  // Add a new branch
  addBranch(
    branchName: string,
    area: string,
    address: string,
    externalBranchUUID,
    canLoadUp: boolean,
    canRedeem: boolean,
    isOnlineBranch: boolean,
    autoDelivers: boolean,
    tenderIDs: number[]
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Branches/Admin/Add';
      this.httpClient
        .post(url, {
          area,
          branchName,
          address,
          externalBranchUUID,
          canLoadUp,
          canRedeem,
          isOnlineBranch,
          autoDelivers,
          tenderIDs,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Branch added successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  getAreas(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Areas/All';
      const areas: string[] = [];
      this.httpClient.get<any>(url).subscribe(
        (areasData: any) => {
          for (const area of areasData) {
            areas.push(area.areaName);
          }
          resolve(areas);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getSalesBranches(): Promise<SalesBranch[]> {
    return new Promise<SalesBranch[]>((resolve, reject) => {
      const url: string = this.baseUrl + '/Branches/Admin/All';
      let branchesArray: SalesBranch[] = [];
      this.httpClient.get<any>(url).subscribe(
        (res) => {
          branchesArray = res.map((branch) => {
            return {
              name: branch.branchName,
              id: branch.branchID,
              collapsed: true,
            };
          });
          resolve(branchesArray);
        },
        (err) => {
          reject(err);
          this.toastService.show('danger', 'Error', err.err);
        }
      );
    });
  }
}
