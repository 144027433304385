export class Machine {
  area: string;
  branchID: number;
  branchName: string;
  deviceSerial: string;
  deviceName: string;
  isActive: boolean;
  machineID: number;
  merchantID: number;
  merchantName: string;
  status: string;

  constructor(
    area: string,
    branchID: number,
    branchName: string,
    deviceSerial: string,
    deviceName: string,
    isActive: boolean,
    machineID: number,
    merchantID: number,
    merchantName: string
  ) {
    this.area = area;
    this.branchID = branchID;
    this.branchName = branchName;
    this.deviceSerial = deviceSerial;
    this.deviceName = deviceName;
    this.isActive = isActive;
    this.machineID = machineID;
    this.merchantID = merchantID;
    this.merchantName = merchantName;
    this.status = this.isActive ? 'Active' : 'Blocked';
  }
}
