<p-dialog class="search-dialog" [(visible)]="displayModal" [resizable]="false" [modal]="true" [closable]="false" (onShow)="onAfterShow($event)">
  <p-autoComplete #searchInput [(ngModel)]="selectedPage" [delay]="0" [autoHighlight]="true" [completeOnFocus]="false"
    placeholder="Search for anything" emptyMessage="We couldn't find anything" [showEmptyMessage]="true"
    [autofocus]="true" [suggestions]="availableActionsBySearch" (completeMethod)="search($event)" field="title"
    [dropdown]="false" [forceSelection]="true" (onSelect)="onSelect($event)">
    <ng-template let-page pTemplate="item">
      <div class="result-item" *ngIf="currentAction === 'navigate' || currentAction === 'supportSearch' || currentAction === 'enterSearchValue'">
        <div class="left-side">
          <nb-icon [icon]="page.icon"></nb-icon>
          <p>{{ page.title }}</p>
        </div>
      </div>
      <div class="result-item" *ngIf="currentAction === 'switchView'">
        <div class="left-side">
          <img [src]="page.logoURL ? page.logoURL : page.merchantLogo" alt="logo" />
          <p>
            <span *ngIf="authService.hasPermissionMerchant(1) && page.organizationShortName">{{page.organizationShortName}} • </span>
            {{ page.organizationName ? page.organizationName : page.merchantName }}
          </p>
          <p-chip *ngIf="page.current" label="CURRENT" color="primary" />
        </div>
        <div class="right-side">
          <p-chip *ngIf="authService.hasPermissionMerchant(1)"> {{page.organizationID ? page.organizationID : page.merchantID}}</p-chip>
          <nb-icon *ngIf="page.merchantName" icon="shopping-cart"></nb-icon>
          <nb-icon *ngIf="page.organizationName" icon="home"></nb-icon>
        </div>
      </div>
    </ng-template>
  </p-autoComplete>
</p-dialog>