import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Shift, ShiftsDetails } from './shift.model';
import { RouteService } from '../../../route.service';
import { ToastService } from '../../../toast.service';
import { environment } from '../../../../environments/environment';
import { Branch } from '../../manage/branches/branch.model';
import { Cashier } from '../../manage/cashiers/cashier.model';
import { lastValueFrom, catchError, throwError, map } from 'rxjs';

@Injectable()
export class ShiftsService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    public toastService: ToastService
  ) {}

  loadBranches(): Promise<Branch[]> {
    const url = this.baseUrl + '/Branches/Admin/All';
    const source$ = this.httpClient.get<any[]>(url).pipe(
      map((branches) =>
        branches.map(
          (branch) =>
            new Branch(
              branch.branchName,
              branch.area,
              branch.address,
              branch.canLoadUp,
              branch.canRedeem,
              branch.organizationName,
              branch.isOnlineBranch,
              branch.autoDeliversProducts,
              branch.tenders,
              branch.externalBranchUUID,
              branch.branchID
            )
        )
      ),
      catchError((error) => {
        this.routeService.checkErr(error).then((err: any) => {
          this.toastService.show('danger', 'Error', err);
        });

        return throwError(() => error);
      })
    );

    return lastValueFrom(source$);
  }

  loadCashiers(): Promise<Cashier[]> {
    const url = this.baseUrl + '/Cashier/Admin/Cashiers';
    const source$ = this.httpClient.get<Cashier[]>(url).pipe(
      catchError((error) => {
        this.routeService.checkErr(error).then((err: any) => {
          this.toastService.show('danger', 'Error', err);
        });

        return throwError(() => error);
      })
    );

    return lastValueFrom(source$);
  }

  getShifts(filters = {}): Promise<Shift[]> {
    const url: string = this.baseUrl + '/merchant-gateway/Shifts/Admin/';
    const params = new HttpParams().appendAll(filters);
    const source$ = this.httpClient.get<Shift[]>(url, { params }).pipe(
      catchError((error) => {
        this.routeService.checkErr(error).then((err: any) => {
          this.toastService.show('danger', 'Error', err);
        });

        return throwError(() => error);
      }),
      map((shifts) =>
        shifts?.map(
          (shift) =>
            new Shift(
              shift.shiftID,
              shift.username,
              shift.cashierName,
              shift.branchName,
              shift.cashierID,
              shift.startTime,
              shift.endTime,
              shift.currencyCode
            )
        )
      )
    );

    return lastValueFrom(source$);
  }

  getShiftsDetails(shiftIDs: number[]): Promise<ShiftsDetails> {
    const url: string = this.baseUrl + '/merchant-gateway/Shifts/Admin/Details';
    const params = new HttpParams().append('shiftIDs', shiftIDs?.join(','));
    const source$ = this.httpClient.get<ShiftsDetails>(url, { params }).pipe(
      catchError((error) => {
        this.routeService.checkErr(error).then((err: any) => {
          this.toastService.show('danger', 'Error', err);
        });

        return throwError(() => error);
      })
    );

    return lastValueFrom(source$);
  }

  async stopShift(shiftID: number,cashierID) {
    const url:string = this.baseUrl + '/merchant-gateway/Shifts/Admin/EndCashierShift';
    const body = { shiftID,cashierID };
    const res = await lastValueFrom(this.httpClient.post(url, body));
    return res;
  }
}
